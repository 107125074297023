

















import Vue from "vue";
import MessageItem from "@/components/MessageItem.vue";

export default Vue.extend({
  name: "ArchivedMessages",
  components: {
    MessageItem
  },
  data: () => ({
    isEditing: false
  }),
  computed: {
    messages(): readonly Messages.UserFeedback[] {
      return this.$store.direct.getters.archivedMessages;
    }
  },
  methods: {
    toggleEditing(): void {
      this.isEditing = !this.isEditing;
    }
  }
});
